<template>
  <fragment>
    <div style="display: flex;">
      <h2 style="flex: 50%;">Deposit Summaries</h2>
      <div style="margin-bottom: 20px;">
        <!-- <button-bar :buttons="paymentProcessorButtons" @onSelect="buttonSelected" />  -->
      </div>
      <!-- <img  style="height: 30%;margin-left: 15px;width: 3%;margin-top: 8px; cursor: pointer;" v-if="payfareButtonSelected" @click="payfareMultiDownload" src="@/assets/images/logos/download.svg"/> -->

    </div>
    <h3>{{ locationName }}</h3>

    <div v-if="payfareButtonSelected">
      <data-table :entries="payfareDepositSummaries" :headers="depositSummaryHeaders" :loading="loader">
        <!-- <template #payPeriod="{ item }"> <input type="checkbox" class="checkbox-input-deposit" @click="payfareMultiSelect(item, $event)"> {{ payPeriodOne(item) }}</template> -->
        <template #payPeriod="{ item }"> {{ payPeriodOne(item) }}</template>
        <template #amount="{ item }"> {{ item.amount | formatMoney("s") }}</template>
        <template #actions="{ item }"><a @click="openPayfareFlyout(item)" @click.prevent>View</a></template>
      </data-table>
    </div>

    <payfare-deposit-summary-flyout name="payfare-deposit-summary" :details="payfareFlyout" :partnerID="partnerID"></payfare-deposit-summary-flyout>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
import DataTable from "@/components/table/DataTable";
import TableHeader from "@/components/table/TableHeader";
import { DateTime } from "luxon";
import { mapActions } from "vuex";
import { restApi } from "@/http/http.config";
// import ButtonBar from "@/components/buttons/ButtonBar";
import { encodeWithParam } from "@/filters";
// import VueHtml2pdf from "vue-html2pdf";
import PayfareDepositSummaryFlyout from "@/modules/admin/accounting/invoicing/PayfareDepositSummaryFlyout";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import { PDFDocument } from 'pdf-lib'



export default {
  name: "PartnerDepositSummaries",
  components: {
    Fragment,
    DataTable,
    // ButtonBar,
    // VueHtml2pdf,
    PayfareDepositSummaryFlyout,
  },
  data() {
    return {
      htmlToPdfOptions: {
        filename: "",
        html2canvas: { useCORS: true, height: 1024, logging: false },
      },
      loader: true,
      selectedSummaries:[],
      partnerData:{},
      achSummaries:[],
      oneSummaries:[],
      depositSummaries: [],
      payfareSummaries: [],
      payfareDepositSummaries: [],
      dayDeposits: {},
      depositSummaryFlyout: {},
      oneFlyout: {},
      payfareFlyout: {},
      holdFlyout: {},
      achFlyout: {},
      locationName: "",
      totalCount: 0,
      // oneTotalCount:0,
      // onePerPage:1,
      // oneCurrentPage:0,
      // oneOffSet:0,
      perPage: 50,
      currentPage: 0,
      offset: 0,
      selectedButton: "payfare",
      // achDailyBreakup: false,
      downloadEnable: false,
    };
  },

  props: {
      partner: {
        type: Object,
        required: true,
        default: () => { }
      }
    },
  computed: {
    partnerID() {
      return this.$route.params.id;
    },
    depositSummaryHeaders() {
      return [
        new TableHeader({ label: "Pay Period", headerClass: "cell30 text-left", name: "payPeriod", cellContents: "payPeriod" }),
        new TableHeader({ label: "Total Earnings", headerClass: "cell25 text-right", name: "amount", cellContents: "amount", cellClass: "text-right" }),
        new TableHeader({ label: "Status", headerClass: "cell25 text-right", name: "status", cellContents: "status", cellClass: "text-right" }),
        new TableHeader({ cellContents: "actions", headerClass: "cell20 text-right", name: "actions", cellClass: "table-actions" }),
      ];
    },
    loadFlyout() {
      return Object.keys(this.depositSummaryFlyout).length > 0 ? true : false;
    },
    paymentProcessorButtons() {
      return [
        { id: "payfare", label: "PAYFARE" },
        // { id: "hold", label: "HOLD" },
      ];
    },
    payfareButtonSelected() {
      return this.selectedButton === "payfare";
    },
    // holdButtonSelected() {
    //   return this.selectedButton === "hold";
    // }
  },

  watch: { 
    partner: function(newVal) {
      this.locationName = newVal.location_name;
    }
  },
  methods: {
    ...mapActions(["showFlyout"]),
    formatDate(date) {
      return DateTime.fromISO(date).toFormat("dd LLL yyyy");
    },

    // payfareMultiSelect(val, e){
    //   console.log(val)
    //   if(e.target.checked){
    //         if (val.id != this.payfareSummaries.map((item) => item.id) && this.payfareSummaries.length > 0) {
    //             this.payfareSummaries.push(val)
    //         }
    //         else{
    //           this.payfareSummaries.push(val)
    //         }
    //   }
    //   else{
    //     this.payfareSummaries.splice(this.payfareSummaries.indexOf(val.id), 1)
    //   }
    // },

    // async payfareMultiDownload() {
    //   debugger
    //   let bufferArry = []
    //   let obj = {}

    //   for (let i = 0; i < this.payfareSummaries.length; i++) {
    //     const pdfDoc = await PDFDocument.create()
    //     const page = pdfDoc.addPage()
    //     const { width, height } = page.getSize()
    //     const headingSize = 20
    //     const fontSize = 10
    //     page.drawText('Deposit Summary', {
    //       x: width - 20 * headingSize,
    //       y: height - 2 * headingSize,
    //       size: headingSize,
    //     })

    //     page.drawText(`
    //         Location: ${this.$props.partner.location_name}
    //         Pay Period Beginning: ${this.payfareSummaries[i].startDate}
    //         Pay Period End: ${this.payfareSummaries[i].endDate}
    //         `, {
    //       x: 50,
    //       y: height - 4 * fontSize,
    //       size: fontSize,
    //     })

    //     let count = 12
    //     const map = new Map()
    //     let MappingKey = {
    //       1: 'Trip Earnings',
    //       2: 'Incentives',
    //       3: 'Tips',
    //       4: 'Earning Adjustment',
    //       5: 'Healthcare Subsidy',
    //       6: 'Tip Coverage',
    //       7: 'Incidentals',
    //       8: 'Sales Tax'
    //     }
    //     for (let j = 0; j < this.payfareSummaries[i]?.dailyBreakup.length; j++) {
    //       const name = MappingKey[this.payfareSummaries[i].dailyBreakup[j].mapping_key]
    //       const key = name
    //       if (map.has(key)) {
    //         map.set(key, { amount: map.get(key).amount + + this.payfareSummaries[i].dailyBreakup[j].payable_amount, counter: map.get(key).counter + 1 })
    //       }
    //       else {
    //         map.set(key, { amount: Number(this.payfareSummaries[i].dailyBreakup[j].payable_amount), counter: 1 })
    //       }
    //     }
    //     let total = 0
    //     for (let [k, v] of map) {
    //       total = v.amount + total
    //       page.drawText(`
    //           ${v.counter}  ${k}:     $ ${v.amount}
    //           `, {
    //         x: 50,
    //         y: height - count * fontSize,
    //         size: fontSize,
    //       })
    //       count = count + 2
    //     }
    //     page.drawText(`
    //         (Net) Total Earnings: $ ${this.payfareSummaries[i].amount}
    //         `, {
    //       x: 50,
    //       y: height - `${count + 2}` * fontSize,
    //       size: fontSize,
    //     })
    //     const pdfBytes = await pdfDoc.save()
    //     let buffer = Buffer.from(pdfBytes, "hex");
    //     let buf64 = buffer.toString("base64");
    //     let bufferData = "data:application/pdf;base64," + buf64
    //     const period = `${this.payfareSummaries[i].startDate}-${this.payfareSummaries[i].endDate}`
    //     obj = {
    //       url: bufferData,
    //       period: period
    //     }

    //     bufferArry.push(obj)
    //   }

    //   if (this.payfareSummaries.length) {
    //     const partnerName = `${this.$props.partner.firstName}_${this.$props.partner.lastName}`
    //     this.saveZip(`${this.$route.params.id}.zip`, partnerName, bufferArry)
    //   }
    // },
    async multiDownload(){
      let bufferArry = []
      let obj = {}

          for(let i = 0; i<this.selectedSummaries.length; i++){
            const pdfDoc = await PDFDocument.create()
          const page = pdfDoc.addPage()
          const { width, height } = page.getSize()
          const headingSize = 20
          const fontSize = 10
          page.drawText('Deposit Summary', {
            x: width - 20 * headingSize,
            y: height - 2 * headingSize,
            size: headingSize,
          })
          page.drawText(`
          Location: ${this.$props.partner.location_name}
          Pay Period: ${this.payPeriod(this.selectedSummaries[i])}

          Business Income:     $ ${this.selectedSummaries[i].check.BUSINESS_INCOME.amount}
          Credits:                     $ ${this.selectedSummaries[i].check.CREDITS.amount}
          Debits:                      $ ${this.selectedSummaries[i].check.DEBITS.amount}
          Other Withholdings:  $ ${this.selectedSummaries[i].check.WITHHOLDING.amount}

          (Net) Total Earnings: $ ${this.selectedSummaries[i].check.NET.amount}
          `, {
            x: 50,
            y: height - 4 * fontSize,
            size: fontSize,
          })

          const pdfBytes = await pdfDoc.save()
          let buffer = Buffer.from(pdfBytes, "hex");
          let buf64 = buffer.toString("base64");
          let bufferData = "data:application/pdf;base64,"+buf64
          const period = this.payPeriod(this.selectedSummaries[i])
          obj = {
            url : bufferData,
            period: period
          }
          
          bufferArry.push(obj)
          }
          if(this.selectedSummaries.length){
            const partnerName = `${this.$props.partner.firstName}_${this.$props.partner.lastName}`
            this.saveZip(`${this.$route.params.id}.zip`,partnerName,bufferArry)
          }
    },

    
    saveZip(filename, partnerName,data) {
        if (!data) return;
        const zip = new JSZip();
        const folder = zip.folder(`${this.$route.params.id}`);

        data.forEach((item) => {
        const blobPromise = fetch(item.url).then((r) => {
            if (r.status === 200) return r.blob();
            return Promise.reject(new Error(r.statusText));
        });
        const name = `${partnerName}_${item.period}.pdf`;
        folder.file(name, blobPromise);
    });
        zip.generateAsync({ type: "blob" }).then((blob) => saveAs(blob, filename));

      },
    formatDatePacific(date) {
      return DateTime.fromISO(date).setZone("America/Los_Angeles").toFormat("dd LLL yyyy")
    },
    formatDateUTC(date) {
      return DateTime.fromISO(date, {zone: 'utc'}).toFormat("dd LLL yyyy")
    },
    openFlyout(item) {
      item["payPeriod"] = this.payPeriod(item);
      this.depositSummaryFlyout = item;
      this.showFlyout("deposit-summary");
    },
    openPayfareFlyout(item) {
      // item["payPeriod"] = this.payPeriod(item);
      // const data = { date: item.payPeriod, locationName: this.locationName };
      item["locationName"] = this.$props.partner.location_name
      this.payfareFlyout = item;
      this.showFlyout("payfare-deposit-summary");
    },
    payPeriod(item) {
      return `${this.formatDate(item.settlement.startDate)} - ${this.formatDatePacific(item.settlement.endDate)}`;
    },
    payPeriodOne(item) {
      return `${this.formatDate(item.startDate)} - ${this.formatDateUTC(item.endDate)}`;
    },
    onPaginate(event) {
      this.currentPage = Number(event.pageNumber);
      this.offset = event.offset;
      this.loader = true;
      this.getPartnerDepos();
    },
    // onOnePaginate(event) {
    //   this.oneCurrentPage = Number(event.pageNumber);
    //   this.oneOffSet = event.offset;
    //   this.loader = true;
    //   this.fetchOneDepositSummaries();
    // },
    async getPartnerDepos() {
      this.loader = true;
      await restApi.post(`/partner/depositSummaries`, {
        "param": window.btoa(JSON.stringify({ id: this.partnerID, limit: this.perPage, offset: this.offset }))
      })
        .then(({ data }) => {
          this.depositSummaries = data?.result.depositSummaries;
          this.totalCount = Number(data?.result.depositSummaries_aggregate);
          this.locationName = data?.result?.depositSummaries[0]?.clientLocation.name
          this.partnerData = 
          this.loader = false;
        })
    },
    grossTotal(item) {
      return item?.check["BUSINESS_INCOME"]?.amount + item?.check["CREDITS"]?.amount;
    },
    buttonSelected(button) {
      this.loader = true;
      this.oneDailyBreakup = false
      // this.achDailyBreakup = false

      this.payfareDepositSummaries = [];
      this.selectedButton = button.id;
      switch (this.selectedButton) {
          case 'payfare':
          this.fetchPayfareDepositSummaries();
          break;

      }
    },

    fetchPayfareDepositSummaries() {
      this.loader = true;
      restApi.post(`/employee/payfareDepositSummariesWeekly`, encodeWithParam({ partnerID: this.partnerID })).then((data) => {
        this.payfareDepositSummaries = data.data.result;
        this.loader = false;
      })
    },

    download(obj) {
      this.loader = true;
      restApi.post(`/employee/getOneEarningsDayDetails`, encodeWithParam({ partnerID: this.partnerID, selectedDate: obj.date })).then((data) => {
        this.depositSummaryData(data.data.result, obj, data.data.partnerInfo)
      })
    },
    depositSummaryData(depositResult, obj, partner) {
      let totalAmount = depositResult.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.totalAmount), 0);
      let MappingKey = {
        1: 'Trip Earnings',
        2: 'Incentives',
        3: 'Confirmed Tips',
        4: 'Earning Adjustment',
        5: 'Healthcare Subsidy',
        6: 'Tip Coverage'
      }
      let credits = depositResult.map((item, index) => {
        return {
          id: index,
          name: MappingKey[item.mapping_key],
          amount: item.totalAmount,
          count: item.count
        }

      })
      depositResult.forEach(() => {
        let customObj = {
          netAmount: totalAmount,
          credits: credits,
          checkDate: obj.date,
          clientLocation: this.$props.partner.location_name,
          payPeriod: obj.date,
          firstName:partner.firstName,
          lastName:partner.lastName,
          partner: {
            name: `${partner.firstName} ${partner.lastName}`,
            email: partner.email,
          },
        };
        this.dayDeposits = customObj;
      });
      this.$refs.html2Pdf.generatePdf()
      this.loader = false;
    },

    enableDownload() {
      this.downloadEnable = true;
    },
  },

  mounted() {
    this.fetchPayfareDepositSummaries()
  }
};
</script>
<style scoped>
.back-btn-week{
  text-align: end;
  margin-top: 20px;
}
.checkbox-input-deposit{
  width: 16px;
    cursor: pointer;
    height: 15px;
    margin-top: 2px;
}
</style>
