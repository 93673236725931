<template>
  <fragment>
      <div class="flex space-between margin-btm-24">
          <h2>Audit Trail</h2>
          <button class="custom-btn" :disabled="disableDownloadButton">
              <img src="@/assets/images/icons/download.png" @click="downloadAuditTrail" />
          </button>
      </div>
      <data-table :entries="auditNotes" :headers="auditNotesHeader" :loading="loader">
          <template #date="{ item }"> {{ formatDate(item.created_on) }} </template>
      </data-table>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
import DataTable from "@/components/table/DataTable";
import TableHeader from "@/components/table/TableHeader";
import { DateTime } from "luxon";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/filters";
import { downloadFile } from "../../../../filters";

export default {
  name: "PartnerAuditTrail",
  components: {
      Fragment,
      DataTable,
  },
  data() {
      return {
          loader: true,
          auditNotes: [],
          disableDownloadButton: true
      };
  },
  computed: {
      partnerID() {
          return this.$route.params.id;
      },
      auditNotesHeader() {
          return [
              new TableHeader({ label: "Date", headerClass: "cell15 text-left", name: "date", cellContents: "created_on" }),
              new TableHeader({ label: "Step", headerClass: "cell15 text-left", name: "module", cellContents: "module" }),
              new TableHeader({ label: "Status", headerClass: "cell10 text-left", name: "status", cellContents: "status", cellClass: "capitalize" }),
              new TableHeader({ label: "Changed by", headerClass: "cell20 text-left", name: "modified_by", cellContents: "modified_by" }),
              new TableHeader({ label: "Audit Notes", headerClass: "cell30 text-left", name: "description", cellContents: "description" }),
          ]
      }
  },
  methods: {
      async getDriverActivityLog() {
          this.loader = true;
          await restApi.post(`/partner/getDriverAuditTrailLog`, encodeWithParam({ partnerID: this.partnerID }))
              .then(({ data }) => {
                  this.auditNotes = data?.result ?? [];
                  this.loader = false;
                  this.disableDownloadButton = false;
              }).catch(() => {
                  this.loader = false;
              })
      },
      async downloadAuditTrail() {
          this.disableDownloadButton = true;
          restApi.post('/partner/downloadAuditTrail', encodeWithParam({ partnerID: this.partnerID })).then((data) => {
              downloadFile(data.data, `Audit Trail.csv`);
              this.disableDownloadButton = false;
          })
      },
      formatDate(date) {
          return DateTime.fromISO(date).setZone("America/Los_Angeles").toFormat("hh:mm a, LL/dd/yyyy");
      },
  },
  mounted() {
      this.getDriverActivityLog();
  }
};
</script>
<style scoped>
.capitalize {
  text-transform: capitalize;
}
.custom-btn {
  padding: 9px 10px;
  border-radius: 4px;
  border: 1px solid #CCC;
  height: 40px;
  cursor: pointer;
  background-color: #FFF;
}
</style>
