<template>
    <fragment>
      <div class="flex space-between margin-btm-24">
        <h2>Insurance History</h2>
        <button class="custom-btn" :disabled="disableDownloadButton">
          <img src="@/assets/images/icons/download.png" @click="downloadInsuranceHistory" />
        </button>
      </div>
      <data-table :entries="insuranceHistory" :headers="insuranceHistoryHeader" :loading="loader">
        <template #date="{ item }"> {{ formatDate(item.date) }} </template>
        <template #fileId="{ item }">
          <div @click="openModal(item.fileid)" class="view-insurance-btn">view</div>
        </template>
      </data-table>
      <image-viewer @click="closeModal" :fileId="selectedInsuranceFileId" />
    </fragment>
  </template>
  
  <script>
  import { Fragment } from "vue-fragment";
  import DataTable from "@/components/table/DataTable";
  import TableHeader from "@/components/table/TableHeader";
  import { DateTime } from "luxon";
  import { restApi } from "@/http/http.config";
  import { encodeWithParam } from "@/filters";
  import { downloadFile } from "../../../../filters";
  import ImageViewer from "@/components/modals/ImageViewer";
  import micromodal from "micromodal";
  
  export default {
    name: "PartnerInsuranceHistory",
    components: {
      Fragment,
      DataTable,
      ImageViewer
    },
    data() {
      return {
        loader: true,
        insuranceHistory: [],
        disableDownloadButton: true,
        selectedInsuranceFileId: ""
      };
    },
    computed: {
      partnerID() {
        return this.$route.params.id;
      },
      insuranceHistoryHeader() {
        return [
          new TableHeader({ label: "Date", headerClass: "cell20 text-left", name: "date", cellContents: "date" }),
          new TableHeader({ label: "Status", headerClass: "cell20 text-left", name: "status", cellContents: "status", cellClass: "capitalize" }),
          new TableHeader({ label: "Changed By", headerClass: "cell35 text-left", name: "modified_by", cellContents: "modified_by"}),
          new TableHeader({ label: "Action", headerClass: "cell10 text-left", name: "fileId", cellContents: "fileid" }),
        ]
      }
    },
    mounted() {
      this.getDriverInsuranceHistory();
    },
    methods: {
      async getDriverInsuranceHistory() {
        this.loader = true;
        await restApi.post(`/partner/getDriverInsuranceHistory`, encodeWithParam({ partnerID: this.partnerID }))
          .then(({ data }) => {
            this.insuranceHistory = data?.result ?? [];
            this.loader = false;
            this.disableDownloadButton = false;
          }).catch(() => {
            this.loader = false;
          })
      },
      async downloadInsuranceHistory() {
        this.disableDownloadButton = true;
        restApi.post('/partner/downloadDriverInsuranceHistory', encodeWithParam({ partnerID: this.partnerID })).then((data) => {
          downloadFile(data.data, `Insurance History.csv`);
          this.disableDownloadButton = false;
        })
      },
      formatDate(date) {
        return DateTime.fromISO(date).setZone("America/Los_Angeles").toFormat("hh:mm a, LL/dd/yyyy");
      },
      openModal(insuranceImageId) {
        this.selectedInsuranceFileId = insuranceImageId;
        micromodal.show('image-viewer');
      },
      closeModal() {
        this.selectedInsuranceFileId = "";
        micromodal.close("image-viewer");
      }
    },
  };
  </script>
  <style scoped>
  .capitalize {
    text-transform: capitalize;
  }
  
  .custom-btn {
    padding: 9px 10px;
    border-radius: 4px;
    border: 1px solid #CCC;
    height: 40px;
    cursor: pointer;
    background-color: #FFF;
  }
  
  .view-insurance-btn {
    color: #0071DC;
    cursor: pointer;
  }
  </style>
  