<template>
  <main>
    <button-bar-tabs :buttons="commsTabs">
      <template #emails>
        <EmailCommunications :partner-id="$route.params.id" />
      </template>
      <template #texts>
        <TextCommunications :partner-id="$route.params.id" />
      </template>
    </button-bar-tabs>
  </main>
</template>

<script>
import TableHeader from "../../../../components/table/TableHeader";
import EmailCommunications from "../../../../components/table/EmailCommunications.vue";
import ButtonBarTabs from "../../../../components/tabs/ButtonBarTabs.vue";
import TextCommunications from "../../../../components/table/TextCommunications.vue";

export default {
  name: "PartnerCommsHistory",
  components: { TextCommunications, ButtonBarTabs, EmailCommunications },
  data: function () {
    return {
      communicationsEmail: [],
      communicationsText: [],
      headersText: [
        new TableHeader({
          label: "To",
          name: "to",
          cellContents: (item) => `${item.to}`,
          sortable: true,
        }),
        new TableHeader({
          label: "Status",
          name: "status",
          sortable: true,
        }),
        new TableHeader({
          label: "Created At",
          name: "updatedAt",
          direction: "desc",
          sortable: true,
          sortFunction: () => (item1, item2) => {
            return new Date(item1.created_at) - new Date(item2.created_at);
          },
        }),
        new TableHeader({
          label: "Updated At",
          name: "updatedAt",
          sortable: true,
          sortFunction: () => (item1, item2) => {
            return new Date(item1.created_at) - new Date(item2.created_at);
          },
        }),
      ],
      headersEmail: [
        new TableHeader({
          label: "To",
          name: "to",
          cellContents: (item) => `${item.to}`,
          sortable: true,
        }),
        new TableHeader({
          label: "Status",
          name: "status",
          sortable: true,
        }),
        new TableHeader({
          label: "Created At",
          name: "updatedAt",
          direction: "asc",
          sortable: true,
          sortFunction: () => (item1, item2) => {
            return new Date(item1.created_at) - new Date(item2.created_at);
          },
        }),
        new TableHeader({
          label: "Updated At",
          name: "updatedAt",
          sortable: true,
          sortFunction: () => (item1, item2) => {
            return new Date(item1.created_at) - new Date(item2.created_at);
          },
        }),
      ],
    };
  },
  computed: {
    commsTabs() {
      return [
        { id: "texts", label: "Texts" },
        { id: "emails", label: "Emails" },
      ];
    },
  },
  methods: {
    external(item) {
      return item.externalIDFull ? item.externalIDFull : item.externalID;
    },
  }
};
</script>

<style scoped></style>
