<template>
  <base-modal :id="partnerModalName" :full="true">
    <add-edit-page>
      <template #header="{ validation }">
        <sticky-header title="Add Business Details">
          <h4>{{ form.profile.firstName }} {{ form.profile.lastName }}</h4>
          <div class="document-toolbar">
            <div class="document-actions">
              <div class="button-group">
                <!-- <button v-if="editingEnabled" class="button" @click="enableEditing()">Save</button> -->
                <button class="button" :disabled="checkValidation(validation) || buttonEnable" @click="handleSubmit(validation)">Save</button>
                <a class="button secondary" @click="cancel(validation, true)">Cancel</a>
              </div>
              <p class="message-align" :class="update.success ? 'success' : 'error'" v-if="displayMessage">{{
                update.message }}</p>
            </div>
          </div>
        </sticky-header>
      </template>
      <template>
        <div class="grid">
          <div class="col-7">
            <div class="spread">
              <div class="module-form">
                <h3>BASIC INFO</h3>
                <div class="para-group">
                  <form-input v-model="form.profile.firstName" v-if="getRole !== 'COORDINATOR'" :disabled="editingEnabled" name="Business Name " label="Business Name" placeholder="Business Name" required rules="specialChar|trailSpaces|hasNum|min:2|max:50" />
                <form-input v-model="form.profile.lastName" v-if="getRole !== 'COORDINATOR'" :disabled="editingEnabled"  label="Business Number" placeholder="Business Number" rules="specialChar|trailSpaces|hasNum|min:2|max:50" required />
                <form-input v-model="form.profile.phone" :disabled="editingEnabled" name="Phone" label="GST/HST Number" type="text" placeholder="GST/HST Number" required />
                </div>
              </div>
              <div class="module-form" v-if="partner.address1 && partner.postalCode">
                <h3>BUSINESS ADDRESS</h3>
                
                <div>
                    <form-input v-model="form.address.address1" :disabled="editingEnabled"  name="address" label="Address1" @input="getAddressSuggestions($event)" required  />
                    <div class="box" v-if="addressSuggestions.length > 0">
                      <div v-for="(item, index) in addressSuggestions" :key="index">
                        <p class="suggestion-item" @click="setAddress(item.item)">{{ item.name }}</p>
                      </div>
                    </div>
                    <form-input v-model="form.address.address2" :disabled="editingEnabled"  name="address" label="Address2"  />
                    <div class="field">
                      <form-input v-model="form.address.state" name="state" label="Province" required :disabled="editingEnabled" />
                    </div>
                    <form-input v-model="form.address.city" name="city" label="City" required :disabled="editingEnabled"  />
                    
                    <form-input v-model="form.address.zip" label="Postal code" required :disabled="editingEnabled" />
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </template>
    </add-edit-page>
  </base-modal>
</template>

<script>
import StickyHeader from "@/components/layout/StickyHeader";
import FormInput from "@/components/forms/fields/FormInput";
import AddEditPage from "@/components/layout/AddEditPage";
import BaseModal from "@/components/modals/BaseModal";
import micromodal from "micromodal";
import { maska } from "maska";
import { restApi } from "@/http/http.config";
// import Datepicker from "vuejs-datepicker";
// import { DriversLicenseValidation } from "@/util/validator";
// import { formatDate } from "@/filters";
import { encodeWithParam } from "@/util/Base64Encoding";
// import Base64 from "base-64";

export default {
  name: "PartnerBusinessAdd",
  directives: { maska },
  components: { AddEditPage, FormInput, StickyHeader, BaseModal },
  data: function () {
    return {
      driverNumberTest: false,
      errorLicense:'',
      ageValidationMessage: false,
      editingEnabled: false,
      buttonEnable: true,
      maskState: '******',
      showThis:false,
      form: {
        profile: {
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          ein: "",
          ssn: "",
          dateOfBirth: "",
        },
        address: {
          address1: "",
          address2: "",
          city: "",
          state: "",
          postalCode: "",
          country: "US",
          addressID: "",
          county: ""
        },
        license: {
          state: "",
          number: "",
          expiration: "",
          id: ""
        },
      },
      displayMessage: false,
      update: {
        success: "",
        message: ""
      },
      validator: true,
      formError: false,
      addressSuggestions: [],
      timeout: "",
      disableAddress: false,
     
    };
  },
  props: {
    partnerModalName: {
      type: String,
      required: false,
      default: "editPartnerModal",
    },
    partner: {
      type: Object,
      required: true,
      default: () => { }
    },
    partnerId: {
      required: true,
      default: "",
    }
  },
  computed: {
    einExist() {
      return this.$props.partner?.businessDetails ? this.$props.partner?.businessDetails[0]?.ein ? true : false : false;
    },
    licenseExist() {
      return this.$props.partner?.driverLicense ? this.$props.partner?.driverLicense[0]?.number ? true : false : false;
    },
    disabledDates() {
      return { to: new Date() }
    },
    searchInput() {
      return this.form.address.address1;
    },
    personalIdent() {
      return this.$props.partner.ssn || this.$props.partner.dateOfBirth || this.einExist
    },
    getRole() {
      return localStorage.getItem('role');
    }
  },
  watch: {
    partnerId() {
      this.prefillForm();
    },
    searchInput(newText, oldText) {
      if (oldText !== "") {
        if (newText !== oldText) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          // const value = newText;
          // this.getAddressSuggestions(value);
        }, 1000);
      }
    },
  },
  methods: {
    dateCheck(val) {
      this.validator = true
      var today = new Date();
      var birthDate = new Date(val);
      var age = today.getFullYear() - birthDate.getFullYear();
      if (age < 18) {
        this.ageValidationMessage = true;
      } else {
        this.ageValidationMessage = false;
        return this.validator = false
      }
    },
   
    
    enableEditing() {
      this.editingEnabled = false;
      this.prefillForm();
    },
    prefillForm() {
      // this.form.profile.firstName = this.$props.partner.businessDetails[0]?.businessName;
      // this.form.profile.lastName = this.$props.partner.businessDetails[0]?.ein;
      // this.form.profile.phone = this.$props.partner.businessDetails[0]?.federalTaxClassification;
     
 
      // this.form.address.state = this.$props.partner.businessDetails[0]?.state;



      // this.form.address.addressID = this.$props.partner.businessDetails[0]?.addressID;

      // this.form.address.address1 = this.editingEnabled ? this.piiMasking(this.$props.partner.businessDetails[0]?.address1) : this.$props.partner.businessDetails[0]?.address1;
      // this.form.address.address2 = this.editingEnabled ? this.piiMasking(this.$props.partner.businessDetails[0]?.address2) : this.$props.partner.businessDetails[0]?.address2;
      // this.form.address.city = this.editingEnabled ? this.piiMasking(this.$props.partner.businessDetails[0]?.city) : this.$props.partner.businessDetails[0]?.city;
      // this.form.address.zip = this.editingEnabled ? this.piiMasking(this.$props.partner.businessDetails[0]?.postalCode) : this.$props.partner.businessDetails[0]?.postalCode;
      // this.form.license.number = this.editingEnabled ? this.piiMasking(this.$props.partner?.driverLicense[0]?.number) : this.$props.partner?.driverLicense[0]?.number;

      

    },

    piiMasking(value) {
      return value?.substring(0, value.length)?.replace(/[a-z\d/-]/gi, "*");
    },
    cancel(validation, reset) {
      micromodal.close(this.partnerModalName);
      document.body.style.overflow = "visible";
      validation.reset();
      this.editingEnabled = true;
      this.validator = true;
      this.disableAddress = false;
      this.addressSuggestions = [];
      if (reset) {
        this.prefillForm();
      }
    },
    checkValidation(val) {
       
      // console.log(this.form.profile.lastName, "sdkjhaskjdhjashd")
      for (let err in val.errors) {
        if (val.errors[err].length > 0) {
          this.formError = true;
          this.buttonEnable = true;
          return true
        }
        
      }
      if(this.form.profile.lastName == undefined || this.form.profile.firstName == undefined || this.form.address.address1 == undefined || this.form.profile.phone == undefined || this.form.address.zip == undefined || this.form.address.state == undefined || this.form.address.city == undefined) {
        this.buttonEnable = true;
      }else{
        this.buttonEnable = false;
      }
      this.formError = false;
      return false
    },
    

    ssnReformat(val){
      let sanitizedSSN = val.replace(/\D/g, '');
      return `${sanitizedSSN.slice(0, 3)}-${sanitizedSSN.slice(3, 5)}-${sanitizedSSN.slice(5)}`;

    },

   
    addSugg(){
      this.showThis = true
    },
    async handleSubmit(validation) {
      this.editingEnabled = true;
      setTimeout(() => {
        this.updateApiCall(validation);
      }, 1000)
    },


    async updateApiCall(validation) {
      
      const driverBusinessDetails = {
        // partnerID:this.$props.partner.id,
        businessName: this.form.profile.firstName,
        businessNumber: this.form.profile.lastName,
        gstHstNumber: this.form.profile.phone,
        address1: this.form.address.address1,
        address2: this.form.address.address2,
        city: this.form.address.city,
        state: this.form.address.state,
        postalCode: this.form.address.zip,
        decoded: {
          partnerID: this.$props.partner.id
        }
      }
      console.log(driverBusinessDetails,validation)
           restApi.post('/onboard/AddBusinessDetails', encodeWithParam(driverBusinessDetails))
           .then(data => {
            console.log(data.data.success)
            console.log(data)
            if(data.data.success){
              micromodal.close(this.partnerModalName);
              document.body.style.overflow = "visible";
              validation.reset();
              location.reload();
            }
            
            });
    },


    trailSpaces(value) {
    const start = /^([ ]+)/
    const end = /([ ]+$)/
    return start.test(value) || end.test(value);
    },
    formatCalendarDate(value) {
      const date = new Date(value).toLocaleDateString('en-US');
      const [month, day, year] = date.split('/');
      return value ? [year, month, day].join('-') : null;
    },
    
    
    
    
    setAddress(data) {
      // if (Object.keys(data).length > 0) {
      //   this.form.address.address1 = data.streetLine;
      //   this.form.address.address2 = data.secondary;
      //   this.form.address.city = data.city;
      //   //this.form.address.county = data.county;
      //   this.form.address.state = data.state;
      //   this.form.address.postalCode = data.zipcode;
      // }
      // this.addressSuggestions = [];


      const searchParams = encodeWithParam({place_id: data});
      restApi.post("/employee/getPlaceDetails", searchParams)
      .then((data) => {
          let addressComponents = data.data.result.result.address_components;
          if (addressComponents.length > 0) {

            this.form.address.address1 =( (addressComponents.find(component => component.types.includes('street_number'))?.long_name || '') + ' ' + (addressComponents.find(component => component.types.includes('route'))?.long_name || '')).normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            this.form.address.city = (addressComponents.find(component => component.types.includes('locality'))?.long_name || '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            this.form.address.state = (addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name || '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            this.form.address.zip = addressComponents.find(component => component.types.includes('postal_code'))?.short_name || '';
            this.addressSuggestions = [];
          }
        })
    },


    getAddressSuggestions(searchText) {
      
      const searchParams = encodeWithParam({inputval: searchText, type: 'address'});
      restApi.post("/employee/placeAutocomplete", searchParams)
        .then(({ data }) => {
          if (data.result.predictions.length > 0) {
            this.addressSuggestions = data.result.predictions.map((item) => {
              return {
                name: `${item.description}`,
                item: `${item.place_id}`,
              };
            });
            this.disableAddress = true;
          } else {
            this.addressSuggestions = [
              {
                name: "Unknown address, click here to enter manually",
                item: {},
              },
            ];
            this.disableAddress = false
          }
        })
    },
    
  }
};
</script>

<style scoped>
.date-pick-width {
  width: 424px;
}

.input-icon-max-width {
  padding-left: 90%;
}

.message-align {
  margin-top: 6px;
  margin-bottom: -12px;
}

.error {
  color: var(--error-40);
}

.success {
  color: var(--success-40);
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.dob-field-bottom {
  margin-bottom: 16px;
}

.box {
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  padding: 5px;
  font-family: "Open Sans";
  position: absolute;
  background-color: white;
  margin-top: -15px;
  z-index: 5;
}

.suggestion-item {
  padding: 5px;
  cursor: pointer;
  margin-bottom: 0;
}

.suggestion-item:hover {
  background-color: lightgray;
}</style>
