<template>
  <fragment>
    <div class="page-header">
      <div class="header-contents">
        <div class="flex" :style="{ justifyContent: 'space-between' }">
          <h1>{{ fullName }}</h1>
          <search />
        </div>
        <div class="grid">
          <div class="col-3">
            <h4>Contact Info</h4>
            <div class="para-group">
             <label>Phone</label><p>{{ partner.phone | formatPhone }}</p>
             <label>Communication Email</label> <p>{{ partner.communication_email }}</p>
              <label>Email </label><p>{{ partner.email }}</p>
            </div>
          </div>
          <div class="col-3">
            <div class="para-group">
              <h4>{{ partner.location_name }}</h4>
              <div>
                <p style="margin-bottom:0.2em;"><label>Origin</label>  {{ partner.origin }}</p>
              </div>
              <label>Status</label>
              <div>
                <a @click="openFlyout">Driver: {{ partner.status || "APPLICANT / NOT SET" }}</a>
              </div>
            </div>
          </div>
          <div v-if="this.getPermission('rwNotes')" class="col-6">
            <div class="field">
              <label>Notes</label>
              <div class="has-charLimit">
                <textarea v-model="notesText"></textarea>
              </div>
              <button class="button small" style="margin-top:5px;" :disabled="notesText==''" @click="saveNote">{{ buttonName }}</button>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="col-3">
            <h4 >Sign Up Date</h4>
            {{ signUpDate | formatDatePacific }}
          </div>
        </div>
      </div>
    </div>
    <tabs :tabs="tabs">
      <template #profile="{ tab }"><partner-profile @onDataLoaded="updateTab(tab, $event)" :partner="partner" @refetch="getPartnerInfo()" /></template>
      <!-- <template #finances="{ tab }"><partner-finances @onDataLoaded="updateTab(tab, $event)" /></template> -->
      <template #deposit-summaries="{ tab }"><partner-deposit-summaries :partner="partner" @onDataLoaded="updateTab(tab, $event)" /></template>
      <!-- <template #documents="{ tab }"><partner-documents @onDataLoaded="updateTab(tab, $event)" /></template> -->
      <template #audit-trail="{ tab }"><partner-audit-trail @onDataLoaded="updateTab(tab, $event)" /></template>
      <template #comms-history="{ tab }"><partner-comms-history @onDataLoaded="updateTab(tab, $event)" /></template>
      <template #partner-notes="{ tab }"><partner-notes @onDataLoaded="updateTab(tab, $event)" /></template>
      <template #error-history="{ tab }"><partner-error-history @onDataLoaded="updateTab(tab, $event)" /></template>
      <template #insurance-history="{ tab }"><partner-insurance-history @onDataLoaded="updateTab(tab, $event)" /></template>

    </tabs>
    <partner-flyout :data="selectedPartner" @statusupdated="getPartnerInfo" @cbcverified="getPartnerInfo" @vehicleDetailsUpdated="onUpdatingVehicleDetails" />
    <candidate-flyout 
      :candidate="candidate" 
      :eligibility-questions="eligibilityQuestions" 
      :partner-application="partnerApplication" 
      :personal-details="personalDetails" 
      :cid="cid"
      :isPartnerPage="true"
      @refresh="getPartnerInfo" 
      @onRemoveInsurance="removeInsuranceImage"
      @insuranceImageUploaded="insuranceImageUploaded"
      @invalidateCandidate="openFlyout"
      @refetchPII="getPersonalDetails"
    />
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import Tabs from "@/components/tabs/Tabs";
import PartnerProfile from "@/modules/admin/partners/profile/PartnerProfile";
//import PartnerFinances from "@/modules/admin/partners/tabs/PartnerFinances";
import PartnerDepositSummaries from "@/modules/admin/partners/tabs/PartnerDepositSummaries";
// import PartnerDocuments from "@/modules/admin/partners/tabs/PartnerDocuments";
import PartnerAuditTrail from "@/modules/admin/partners/tabs/PartnerAuditTrail";
import PartnerErrorHistory from "@/modules/admin/partners/tabs/PartnerErrorHistory.vue";
import PartnerNotes from "@/modules/admin/partners/tabs/PartnerNotes";
import PartnerCommsHistory from "@/modules/admin/partners/tabs/PartnerCommsHistory";
//import { SET_CANDIDATE_NOTES_NEW } from "@/modules/admin/onboarding/graph/mutations";
import Search from "@/components/search/Search";
import PartnerInsuranceHistory from "@/modules/admin/partners/tabs/PartnerInsuranceHistory";
import { DateTime } from "luxon";
import { restApi } from "@/http/http.config";
import PartnerFlyout from "@/modules/admin/onboarding/flyouts/PartnerFlyout";
import { mapActions, mapGetters } from "vuex";
import CandidateFlyout from "@/modules/admin/onboarding/flyouts/CandidateFlyout";
import {encodeWithParam} from "@/util/Base64Encoding";
import { decode, decrypt } from "@/filters";
import { convertBufferToContent } from "@/util/BufferContentConverter";
import { FunnelStatus} from "@/util/funnelProgress";

export default {
  name: "PartnerPage",
  components: {
    Search,
    PartnerCommsHistory,
    PartnerNotes,
    PartnerAuditTrail,
    // PartnerDocuments,
    PartnerDepositSummaries,
    //PartnerFinances,
    PartnerErrorHistory,
    PartnerInsuranceHistory,
    PartnerProfile,
    Tabs,
    Fragment,
    PartnerFlyout,
    CandidateFlyout
  },
  data: function () {
    return {
      startDayWeek: 6,
      cid:"",
      partner: {},
      notes: {},
      buttonName: "Save Note",
      notesText:"",
      partnerId:"",
      // tabs: [
      //   { key: "profile", label: "Profile" },
      //   //{ key: "finances", label: "Finances" },
      //   { key: "deposit-summaries", label: "Deposit Summaries" },
      //   // { key: "documents", label: "Documents" },
      //   { key: "comms-history", label: "Comms History" },
      //   { key:"partner-notes" , label:"Notes"},
      //   { key:"error-history", label:"Error History"},
      //   { key: "audit-trail", label: "Audit Trail" },
      //   { key: "insurance-history", label:"Insurance History" }

      // ],
      selectedPartner: {},
      candidate: {},
      eligibilityQuestions: [],
      partnerApplication: [],
      personalDetails: {},
      signUpDate: ""
    };
  },
  computed: {
    ...mapGetters(["isFlyoutShowing" , "getPermission","getRole"]),
    fullName() {
      return this.partner.firstName ? `${this.partner.firstName} ${this.partner.lastName}` : "";
    },
    partnerID() {
      return this.$route.params.id;
    },
    tabs() {
      const tabs = [
        { key: "profile", label: "Profile" },
        ...(this.getPermission("viewDeposits")?[{key: "deposit-summaries", label: "Deposit Summaries"}]:[]),
        // ...(this.getPermission("viewDocuments")?[{ key: "documents", label: "Documents" }]:[]),
        { key: "comms-history", label: "Comms History" },
        ...(this.getPermission("rwNotes")?[{ key: "partner-notes", label:"Notes" }]:[]),
        { key: "error-history",label:"Error History"},
        ...(this.getPermission("viewAdutTrial")?[{ key: "audit-trail", label: "Audit Trail" }]:[]),
        ...((this.getPermission("viewInsuranceHistory") )?[{ key: "insurance-history", label: "Insurance History" }]:[]),
      ]
      
      return tabs
    }
  
  },
  methods: {
    ...mapActions(["showFlyout", "getRole","getPermission"]),
    updateTab(selectedTab, error) {
      this.tabs.filter((tab) => tab.key === selectedTab.key).forEach((tab) => (tab.error = error));
      this.tabs = [...this.tabs];
    },
    formatSSN(ssn) {
      if (ssn && ssn?.split("-").length == 1) {
        return `${ssn?.substring(0,3)}-${ssn?.substring(3,5)}-${ssn?.substring(5,9)}`
      }
      return ssn
    },
    async saveNote() {
      let localObj= {
        "user":localStorage.getItem("role"),
        "date":DateTime.utc().toJSDate(),
        "message":this.notesText
      }
      if(!this.notes) {
        this.notes={ "notes":[] }
      }
      this.notes.notes.push(localObj);
      await restApi.put(`/partner/saveNotes/${this.partner.id}`, {
        "param": window.btoa(JSON.stringify({ notes: this.notes })) 
      })
      .then(() => {
        this.notesText="";
      })
    },
    async getPartnerInfo(data) {
      if(data) this.selectedPartner[data.field] = data.data;

      await restApi.post(`/partner/info`, { 
        "param": window.btoa(JSON.stringify({ partnerID: this.$route.params.id }))
      })
      .then(async ({data}) => {
        this.partner = data.result;
        this.notes = data.result.notesList;
        this.partner.ssn = await decrypt(this.partner.ssn)
        if (this.getRole == 'COORDINATOR') {
          this.partner.ssn = this.formatSSN(data.result.ssn);
        }
        this.cid = this.partner.candidate_id;
      })
    },
    async openFlyout() {
      if(this.partner.id){
        if(this.partner.approvalDate) {
        this.partnerFlyoutData();
      } else {
          this.candidateFlyoutData(this.partner.id);
          this.showFlyout("candidate-flyout");
      }
      }
    },
    async partnerFlyoutData() {
      const params = {
        partnerID: this.partnerID, 
        candidateID: this.partner.candidate_id,
        clientLocationPartnerID: this.partner.candidate_id,
        clientID: process.env.VUE_APP_CLIENT_ID,
        clientLocationID: this.partner.clientLocationID,
        externalID: this.partner.DMS_id,
      }
      const getPartnerDetails = await restApi.post('partner/getPartnerDetails',encodeWithParam(params))
      const partnerData = getPartnerDetails.data.PartnerDetails[0];
      let insData = this.getInsuranceFileId(getPartnerDetails.data.docs);

      // return
      let flyoutData= { 
        candidateID: partnerData?.id ? partnerData.id:"",
        partnerID: partnerData?.partnerID ? partnerData.partnerID:"",
        firstName: partnerData?.firstName ? partnerData.firstName :"",
        lastName: partnerData?.lastName ? partnerData.lastName:"",
        name:`${partnerData.firstName} ${partnerData.lastName}`,
        phone: partnerData?.phone ? partnerData.phone:"",
        email: partnerData?.email ? partnerData.email:"",
        communication_email: partnerData?.communication_email ? partnerData.communication_email:"",
        approvalDate:partnerData?.approvalDate ? partnerData.approvalDate:"",
        externalID: partnerData?.externalID ? partnerData.externalID:"",
        locationName:partnerData?.locationName ? partnerData.locationName:"",
        dateOfBirth: partnerData?.dateOfBirth ? partnerData.dateOfBirth:"",
        insuranceExpiry: partnerData?.insuranceExpiry ? partnerData.insuranceExpiry:"",
        insuranceVerified: partnerData.insuranceVerified,
        userID: partnerData?.userID ? partnerData.userID:"",
        username: partnerData?.username ? partnerData.username:"",
        dlNumber: partnerData?.number ? partnerData.number:"",
        dlExpiration:partnerData?.expiration ? partnerData.expiration:"",
        dlState: partnerData?.state ? partnerData.state:"",
        status: partnerData?.status ? partnerData.status:"",
        reportConfig:getPartnerDetails.data.CheckrReportConfig.length ? JSON.parse(getPartnerDetails.data.CheckrReportConfig[0].value) : { rootCheckrLink: "", checkrTestQuery: "" },
        cbcDate:getPartnerDetails.data.CBCDate[0] ? getPartnerDetails.data.CBCDate[0].reportDate : [],
        notesList:getPartnerDetails.data.Notes.length ? getPartnerDetails.data.Notes[0].notesList : [],
        lastPay:getPartnerDetails.data.lastPay[0] ? getPartnerDetails.data.lastPay[0].endDate : [],
        // insuranceImageURL:getPartnerDetails?.data?.insuranceData?.file?.path ? getPartnerDetails.data.insuranceData.file.path:null,
        locationID:this.partner.clientLocationID,
        decoded: partnerData.encodedData ? decode(partnerData.encodedData) : "",
        insuranceFileId: insData.fileID,
        documentTemplateID: insData.documentTemplateID,
        vehicle_make: partnerData.vehicle_make,
        vehicle_color: partnerData.vehicle_colour,
        vehicle_year: partnerData.vehicle_year,
        vehicle_plate: partnerData.vehicle_licence_plate,
        vehicle_model: partnerData.vehicle_model,
      }
      this.selectedPartner=flyoutData
      this.showFlyout("partner-flyout");
      // this.getDocumentsPartner(getPartnerDetails.data.docs,flyoutData)
    },
    getInsuranceFileId(docs) {
      try {
        let data = docs.filter((item) => item.title.toLowerCase() === "insurance")[0];
        return { fileID: data.fileID, documentTemplateID: data.id };
      } catch (error) {
        return {};
      }
    },
    onUpdatingVehicleDetails(data) {
      this.selectedPartner = {
        ...this.selectedPartner,
        vehicle_make: data.make,
        vehicle_color: data.color,
        vehicle_year: data.year,
        vehicle_plate: data.licensePlate,
        vehicle_model: data.model,
      };
    },
    // async getDocumentsPartner(docs,flyoutData) {
    //     let insuranceFileDetails = docs.filter((item)=>item.title.toLowerCase() === "insurance");
    //     flyoutData.documentTemplateID=insuranceFileDetails[0]?.id?insuranceFileDetails[0]?.id:null
    //     let data = await restApi.post('/employee/getUploadedFile',encodeWithParam({fileID: insuranceFileDetails[0].fileID}));
    //     flyoutData.insuranceImageURL = data.data.bufferInHex ? convertBufferToContent(data.data.bufferInHex,data.data.contentType):null;
    //       this.selectedPartner=flyoutData
    //       this.showFlyout("partner-flyout");
    // },


    convertDate(date){
      let fullDate=date.split('T');
      return fullDate[0]
    },
    candidateFlyoutData(id) {
      return restApi.post('onboard/getCandidateDetails',encodeWithParam({candidateID: id}))
        .then(async ({data}) => {
          if (data) {
            const candidate = data;
            const candidateProgress = candidate.candidateProgress?.length > 0 ? candidate.candidateProgress[0] : null;
            let timeInStep = "";
            if (candidateProgress) {
              const timeDifference = DateTime.utc().diff(DateTime.fromISO(candidateProgress.date), ["minutes", "hours", "days"]);
              timeInStep =
                timeDifference.values.days > 0
                  ? `${timeDifference.values.days}d`
                  : timeDifference.values.hours > 0
                  ? `${Math.round(timeDifference.values.hours)}h`
                  : `${Math.round(timeDifference.values.minutes)}m`;
            }
            this.candidate = {
              id: candidate.id,
              electronicSignature: candidate.electronicSignature,
              partnerID: candidate.partner.id,
              name: `${candidate.partner.firstName || ""} ${candidate.partner.lastName || ""}`.trim(),
              location: candidate.location.name,
              locationID: candidate.location.id,
              locationCountCurrent: candidate.location.stageCounts?.length > 0 ? await this.approvedCount(candidate.location.stageCounts) : 0,
              locationCountTotal: candidate.location.headCountRequests?.length > 0 ? await this.totalApprovedCount(candidate.location.headCountRequests) : 0,
              email: candidate.partner.email,
              communication_email: candidate.partner.communication_email,
              phone: candidate.partner.phone,
              time: timeInStep,
              stage: candidateProgress ? candidateProgress.step.stage : "",
              step: candidateProgress ? candidateProgress.step.name : "",
              status: candidateProgress ? candidateProgress.candidateStatus : "",
              candidateReason: candidateProgress.candidateStatusDetail ? candidateProgress.candidateStatusDetail : "",
              candidateProgressId: candidateProgress.id,
              alertTime: candidateProgress ? candidateProgress.alertTime : null,
              specialist: candidate?.location?.teamSpecialist ? this.computeEmployeeName(candidate.location.teamSpecialist.employee) : "",
              userID: candidate?.partner?.userID ? candidate.partner.userID : "",
              externalID: candidate?.partner?.ids[0]?.externalID ? candidate.partner.ids[0].externalID : null,
              username: candidate?.partner?.user?.username,
              userId: candidate?.partner?.user?.id,
              approvalDate: candidate?.approvalDate ? candidate.approvalDate : "",
              notes: candidate.notes,
              notesList: candidate.notesList,
              clientID: candidate?.location?.client?.id,
              insuranceExpiry: candidate.partner.insuranceExpiry ? candidate.partner.insuranceExpiry : ""
            };
            const checkDocumentsComplete = await restApi.post('onboard/getFunnelProgress',encodeWithParam({candidateID: this.candidate.id}));
            const documentComplete = checkDocumentsComplete.data.result.filter((item)=>item.candidateStatus === FunnelStatus.DOCUMENTS_COMPLETE)
            if(documentComplete.length > 0) {
              this.getDocuments()
            }
            this.getFunnelQuestions(this.candidate?.partnerID,this.candidate?.locationID);
            this.getPersonalDetails(this.candidate.id);
            // this.insuranceImageUploaded();
            //this.getFunnelAnswers(this.candidate?.partnerID);
          }  
        })
    },
    removeInsuranceImage() {
      this.candidate.insuranceImageURL =null;
      this.candidate.insuranceExpiry=null;
    },
    insuranceImageUploaded() {
      this.getDocuments();
    },
    getDocuments() {
      restApi.post('/onboard/getDocuments', encodeWithParam({
        clientLocationPartnerID: this.partner.candidate_id,
        clientID: process.env.VUE_APP_CLIENT_ID,
        clientLocationID: this.partner.clientLocationID,
        userID:this.candidate.userID
      }))
      .then((docs) => {
        let insuranceFileDetails = docs.data.result.filter((item)=>item.title.toLowerCase() === "insurance");
        restApi.post('/employee/getUploadedFile',encodeWithParam({fileID: insuranceFileDetails[0].fileID}))
          .then((data)  =>  {
            this.candidate = {...this.candidate,allDocs:docs.data.result,insuranceImageURL:convertBufferToContent(data.data.bufferInHex,data.data.contentType)};
          })
      })
    },
    getFunnelQuestions(partnerId,locationId) {
      restApi.post('/onboard/getFunnelQuestions',encodeWithParam({partnerID:partnerId,clientLocationID:locationId})).then((data)=>{
        this.eligibilityQuestions =data.data.eligibilityQuestions;
        this.partnerApplication = data.data.eligibilityAnswers;
      })
    },
    getPersonalDetails(id) {
      restApi.post('/onboard/getPersonalDetails',encodeWithParam({candidateID: id})).then(async(data)=>{
        const decodedSsn = decode(data.data.personalDetails?.encodedData)
        const decryptedSnn = await decrypt(decodedSsn.dssn)
        this.personalDetails = {
          ssn: '*****'+decryptedSnn.substring(5),
          ein: data.data.personalDetails.ein,
          fullSsn: data.data.personalDetails.ssn ? data.data.personalDetails.ssn : null,
          fullEin: data.data.personalDetails.ein ? data.data.personalDetails.ein : null,
          dob: data.data.personalDetails.dateOfBirth ? data.data.personalDetails.dateOfBirth:"",
          licenseNumber: data.data.driversLicenses.number ? data.data.driversLicenses.number:"",
          licenseExpiry: data.data.driversLicenses.expiration ? data.data.driversLicenses.expiration:"",
          licenseState: data.data.driversLicenses.state ? data.data.driversLicenses.state:"",
          insuranceExpiry: data.data.personalDetails.insuranceExpiry ? data.data.personalDetails.insuranceExpiry:"",
          streetAddress: data.data.personalDetails.address1 ? data.data.personalDetails.address1:"",
          address2:data.data.personalDetails.address2 ? data.data.personalDetails.address2:"",
          city: data.data.personalDetails.city ? data.data.personalDetails.city:"",
          state: data.data.personalDetails.state ? data.data.personalDetails.state:"",
          zip: data.data.personalDetails.postalCode ?data.data.personalDetails.postalCode:"",
          wasConvicted: data.data.criminalHistory?.wasConvicted ? data.data.criminalHistory.wasConvicted:false,
          convictionDescription: data.data.criminalHistory?.convictionDescription ? data.data.criminalHistory.convictionDescription:"",
          hasPendingCharges: data.data.criminalHistory?.hasPendingCharges ? data.data.criminalHistory.hasPendingCharges:false,
          pendingChargesDescription: data.data.criminalHistory?.pendingChargesDescription ? data.data.criminalHistory.pendingChargesDescription:"",
          isSexOffender: data.data.criminalHistory?.isSexOffender ? data.data.criminalHistory.isSexOffender:false,
          stateRegisteredYear: data.data.criminalHistory?.stateRegisteredYear ? data.data.criminalHistory.stateRegisteredYear:"",
          stateRegistered: data.data.criminalHistory?.stateRegistered ?data.data.criminalHistory.stateRegistered:"",
          countyRegistered: data.data.criminalHistory?.countyRegistered ? data.data.criminalHistory.countyRegistered:"",
          decoded: data.data.personalDetails.encodedData ? decode(data.data.personalDetails.encodedData) : ""
        };
          this.personalDetails.decoded.dssn = await decrypt(this.personalDetails.decoded.dssn)
      })
    },
    maskNumber(value){
      if(value){
        if(value.length < 8){
          return `****${value.replace(/\d(?=\d{4})/g, "*")}`
        }
        else{
          return value.replace(/\d(?=\d{4})/g, "*");
        }
        
      }
      else{
        return "";
      }
      
    },
    async signDatafunct(id){
      await restApi.post('onboard/getFunnelProgress',encodeWithParam({candidateID : id}))
      .then(({data}) => {
        const signupArr = data.result.filter((item) =>  item.candidateStatus === FunnelStatus.CREATED);
        (this.signUpDate = signupArr[0]?.date);
      })
    },
    async approvedCount(stageCounts) {
      // let count = stageCounts.filter((item)=>item.weekly === this.getRecruitingStartDate("SATURDAY"));
      // return count.length > 0 ? Number(count[0].count) : 0
      const data = await restApi.get(`/location/getPendingHCR`)
      let count = stageCounts.filter((item)=>item.weekly === data.data.pendingHCR[0]?.startDate);
      return count.length > 0 ? Number(count[0].count) : 0
    },
    getRecruitingStartDate (day) {
      var currentDate = new Date();
      var startDate = 1;
        switch (day) {
            case "MONDAY":
                startDate = 6;
                break;
            case "TUESDAY":
                startDate = 5;
                break;
            case "WEDNESDAY":
                startDate = 4;
                break;
            case "THURSDAY":
                startDate = 3;
                break;
            case "FRIDAY":
                startDate = 2;
                break;
            case "SATURDAY":
                startDate = 1;
                break;
            case "SUNDAY":
                startDate = 0;
                break;
        }
      return new Date(currentDate.setDate(currentDate.getDate() - (currentDate.getDay() + startDate) % 7)).toISOString().split("T")[0];
    },
    async totalApprovedCount(totalCandidates) {
      // const startweek = this.startDayWeek > DateTime.now().weekday ? DateTime.now().weekNumber - 1 : DateTime.now().weekNumber;
      // const approvedCandidates = totalCandidates.filter((item) => item.startDate === DateTime.utc().startOf("day").set({ weekNumber: startweek, weekday: this.startDayWeek }).toFormat("yyyy-MM-dd"));
      // return approvedCandidates && approvedCandidates?.length > 0 ? approvedCandidates[0].count : 0;
      const data = await restApi.get(`/location/getPendingHCR`)
      const approvedCandidates = totalCandidates.filter((item) => item.startDate === data.data.pendingHCR[0]?.startDate)
      return approvedCandidates && approvedCandidates?.length > 0 ? approvedCandidates[0].count : 0;
    },
    computeEmployeeName(employee) {
      if (employee) {
        return `${employee.firstName} ${employee.lastName}`.trim();
      }
      return "none";
    }
  },
  async mounted() {
    await this.getPartnerInfo();
    await this.signDatafunct(this.partner.candidate_id);
  }
};
</script>
