var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Text Messages")]),_c('data-table',{attrs:{"headers":_vm.headersText,"entries":_vm.communicationsText,"pagination-total-count":_vm.totalCount,"loading":_vm.textLoader,"pagination-per-page":_vm.perPage,"pagination-current-page":_vm.currentPage},on:{"onPaginate":function($event){return _vm.onPaginate($event)}},scopedSlots:_vm._u([{key:"to",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatPhone")(item.to.toString())))]}},{key:"status",fn:function(ref){
var item = ref.item;
return [(item.externalIDFull)?_c('a',{attrs:{"href":("https://app.sendgrid.com/email_activity/" + (item.externalIDFull)),"target":"_blank"}},[_vm._v(_vm._s(item.externalStatus))]):_c('span',[_vm._v(_vm._s(item.externalStatus)+" ")])]}},{key:"createAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDateTimePacific")(item.created_at)))]}},{key:"updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDateTimePacific")(item.updated_at)))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }